$(document).ready(function(){
  $('.about__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    speed: 300,
    prevArrow: '.slide__prev',
    nextArrow: '.slide__next'
  });

  $('.center__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      infinite: true,
      speed: 300,
      prevArrow: '.center-slide__prev',
      nextArrow: '.center-slide__next'
  });

    $('.performance__slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        speed: 300,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
