$(document).ready(function() {

  // $('#headerCallBack, #footerCallBack, #speakerCallBack, #priceCallBack-1, #priceCallBack-2, #priceCallBack-3, #priceCallBack-4').magnificPopup({
  //   type: 'inline',
  //   preloader: false,
  //   focus: '#popupName'
  // });

  $('.main-content__entrance-cabinet-form-link').magnificPopup({
      type: 'inline',
      preloader: false
  });
});
