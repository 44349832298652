$(document).ready(function() {

    //Действия по умолчанию
    $(".performance__tab-content").hide(); //скрыть весь контент
    $(".performance__tabs .performance__tab:first").addClass("active").show(); //Активировать первую вкладку
    $(".performance__tab-content:first").show(); //Показать контент первой вкладки

    //Событие по клику
    $(".performance__tabs .performance__tab").click(function() {
        $(".performance__tabs .performance__tab").removeClass("active"); //Удалить "active" класс
        $(this).addClass("active"); //Добавить "active" для выбранной вкладки
        $(".performance__tab-content").hide(); //Скрыть контент вкладки
        var activeTab = $(this).find("a").attr("href"); //Найти значение атрибута, чтобы определить активный таб + контент
        $(activeTab).fadeIn(); //Исчезновение активного контента
        return false;
    });


    //Действия по умолчанию
    $(".guestbook__tab-content").hide(); //скрыть весь контент
    $(".guestbook__tabs .guestbook__tab:first").addClass("active").show(); //Активировать первую вкладку
    $(".guestbook__tab-content:first").show(); //Показать контент первой вкладки

    //Событие по клику
    $(".guestbook__tabs .guestbook__tab").click(function() {
        $(".guestbook__tabs .guestbook__tab").removeClass("active"); //Удалить "active" класс
        $(this).addClass("active"); //Добавить "active" для выбранной вкладки
        $(".guestbook__tab-content").hide(); //Скрыть контент вкладки
        var activeTab = $(this).find("a").attr("href"); //Найти значение атрибута, чтобы определить активный таб + контент
        $(activeTab).fadeIn(); //Исчезновение активного контента
        return false;
    });


});
