window.addEventListener('DOMContentLoaded', onStart);

function onStart() {
    var burger = document.getElementById('burger');

    burger.addEventListener('click', function () {
        var menu = document.getElementById('menu');
        burger.classList.toggle('open');
        menu.classList.toggle('show');
    });

    initCustomSelect('.main-content__materials-month');

    function initCustomSelect(customSelect) {
        var date = new Date();
        var array = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
        var mainCustomSelect = document.querySelector(customSelect);

        if (mainCustomSelect) {
            var mainCustomSelectClass = customSelect.split('__');
            var name = mainCustomSelectClass[1] + '-select';

            var mainCustomHeader = document.createElement('div');
            mainCustomHeader.classList.add(name + '__header');

            var mainCustomHeaderText = document.createElement('div');
            mainCustomHeaderText.classList.add(name + '__header-text');
            mainCustomHeaderText.innerHTML = array[date.getMonth()] + ' ' + date.getFullYear();

            mainCustomHeader.appendChild(mainCustomHeaderText);

            var mainCustomBody = document.createElement('div');
            mainCustomBody.classList.add(name + '__body');

            var mainCustomYear = document.createElement('div');
            mainCustomYear.classList.add(name + '__year');

            var mainCustomPrev = document.createElement('div');
            mainCustomPrev.classList.add(name + '__prev');
            mainCustomPrev.classList.add(name + '__nav');
            mainCustomPrev.addEventListener('click', function () {
                mainCustomYears.innerHTML -= 1;
            });

            var mainCustomNext = document.createElement('div');
            mainCustomNext.classList.add(name + '__next');
            mainCustomNext.classList.add(name + '__nav');
            mainCustomNext.addEventListener('click', function () {
                mainCustomYears.innerHTML = +mainCustomYears.innerHTML + 1;
            });

            var mainCustomYears = document.createElement('div');
            mainCustomYears.classList.add(name + '__years');
            mainCustomYears.innerHTML = date.getFullYear();

            var mainCustomMonths = document.createElement('div');
            mainCustomMonths.classList.add(name + '__months');

            for (var i = 0; i < array.length; i++) {
                mainCustomMonths.appendChild(getMonth(name, i, array, date.getMonth()));
            }

            var mainCustomSubmit = document.createElement('div');
            mainCustomSubmit.classList.add(name + '__submit');
            mainCustomSubmit.innerHTML = 'Выбор';
            mainCustomSubmit.addEventListener('click', submit);

            mainCustomYear.appendChild(mainCustomPrev);
            mainCustomYear.appendChild(mainCustomYears);
            mainCustomYear.appendChild(mainCustomNext);

            mainCustomBody.appendChild(mainCustomYear);
            mainCustomBody.appendChild(mainCustomMonths);
            mainCustomBody.appendChild(mainCustomSubmit);

            mainCustomSelect.appendChild(mainCustomHeader);
            mainCustomSelect.appendChild(mainCustomBody);


            mainCustomHeader.addEventListener('click', function () {
                this.classList.toggle('active');
                mainCustomBody.classList.toggle('show');
            });
        }

        function submit() {
            var ajaxObject = {}; // for AJAX
            var year = mainCustomYears.innerHTML;
            var month = mainCustomMonths.querySelector('.active').dataset.month;
            var monthName = mainCustomMonths.querySelector('.active').innerHTML;

            ajaxObject.month = month;
            ajaxObject.year = year;

            console.log(ajaxObject);
            updateHeader(monthName, year, mainCustomHeaderText);
        }
    }

    function getMonth(name, month, array, now) {
        var customMonth = document.createElement('div');
        customMonth.classList.add(name + '__month');
        customMonth.setAttribute('data-month', month);
        customMonth.innerHTML = array[month];

        if (month === now) {
            customMonth.classList.add('active');
        }

        customMonth.addEventListener('click', setActiveMonth);

        return customMonth;
    }

    function setActiveMonth() {
        var parent = this.parentElement;
        var months = parent.children;

        for (var i = 0; i < months.length; i++) {
            months[i].classList.remove('active');
        }

        this.classList.add('active');
    }

    function updateHeader(month, year, header) {
        header.innerHTML = month + ' ' + year;
    }
}

var places = document.getElementsByClassName('scheme__places-item');

for (var i = 0; i < places.length; i++) {
    places[i].addEventListener('click', setBuyingPlaces)
}

function setBuyingPlaces() {
    if (this.classList.contains('chosen')) {
        this.classList.remove('chosen');
        removePlaceDescription(this);
    } else {
        this.classList.add('chosen');
        setPlaceDescription(this);
    }
}

function setPlaceDescription(place) {
    var orderPlaceList = document.getElementById('orderPlaceList');
    var item = document.createElement('div');
    item.classList.add('order-places__place');
    item.innerHTML = 'ряд ' + getPlaceCoordinates(place) + ', место ' + place.innerHTML;
    orderPlaceList.appendChild(item);
}

function removePlaceDescription(place) {
    var orderPlaceList = document.getElementById('orderPlaceList');
    var items = orderPlaceList.children;
    for(var i = 0; i < items.length; i++) {
        if (items[i].innerHTML === 'ряд ' + getPlaceCoordinates(place) + ', место ' + place.innerHTML) {
            orderPlaceList.removeChild(items[i]);
        }
    }
}

function getPlaceCoordinates(place) {
    var parent = place.parentElement;
    var row = parent.getAttribute('data-row');
    return row;
}

;(function(event) {
  var goBack = document.querySelector('.go-back__submit');
  if (goBack) {
    goBack.addEventListener('click', function () {
      setTimeout('window.history.back()', 1000);

    });
  }

  event.preventDefault();
});
